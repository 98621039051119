<template>
    <div class="auth-edit">
       <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">信用授权管理</div>
        <div class="card">
            <div class="details">
                <p class="fontweight">银行名称</p>
                <p>{{routerObj.bankName}}</p>
                <br/>
                <p class="fontweight">授权截止时间</p>
                <p>{{routerObj.endTime}}</p>
                <br/>
                <p class="fontweight">授权时间延迟</p>
                <van-radio-group v-model="checked" direction="horizontal">
                    <van-radio name="1">一个月</van-radio>
                    <van-radio name="2">两个月</van-radio>
                    <van-radio name="3">三个月</van-radio>
                    <van-radio name="0">停止授权</van-radio>
                </van-radio-group>
            </div>
            <div style="text-align: center">
                <div class="save" @click="saveEdit">保存</div>
            </div>
            <!-- <img class="status" src="@/assets/img/auth_weiguoqi.png" alt=""> -->
            <img v-if="routerObj.status === '0'" class="status" src="@/assets/img/auth_guoqi.png" alt="">
            <img v-else class="status" src="@/assets/img/auth_weiguoqi.png" alt="">
        </div>

    </div>
</template>
<script>
import { ref,reactive } from 'vue';
import { apiBankendtimeEdit } from '../../api/axios';
import { Toast,Dialog} from 'vant';
import { useRouter } from 'vue-router';
export default {
    setup() {
        let router = useRouter();
        let routerObj = JSON.parse(decodeURIComponent(router.currentRoute.value.query.id));
        const checked = ref('1');
        // console.log(routerObj);
        const saveEdit= () => {
            let obj = {
                bankEndTimeId: routerObj.bankEndTimeId,
                authTime: checked.value
            }
            apiBankendtimeEdit(obj).then(res => {              
                if(res.code == '1'){
                    Toast('编辑成功');
                    router.go(-1);
                }
                else {
                Toast(res.msg);
                }
            });

        };
        return {
            routerObj,
            checked,
            saveEdit
        }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
}
</script>
<style lang="less" scoped>
.auth-edit {
    padding-top: 12.5vw;    
    .card {
        position: relative;
        .details {
            width: 100%;
            font-size: 4vw;
            color: #666666;
            padding: 2vw 0;
            p {
            margin: 1.5vw 0;
            }
            p.fontweight {
                font-weight: 600;
            }
            
        }
        .save {
            display: inline-block;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            font-size: 4vw;
            padding: 2vw 6vw;
            border-radius: 6vw;
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
}
</style>
<style lang="less">
.auth-edit {
     .van-radio__icon--checked .van-icon,
        .van-radio__icon .van-icon {
            display: none;
        }
        .van-radio__label {
            background-color: #f5f5f5;
            font-size: 4vw;
            padding: 2vw 2vw;
            border-radius: 1vw;
            margin-top: 2vw;
        }
        .van-radio[aria-checked="true"] .van-radio__label {
            color: #fff;
            background: linear-gradient(to right, #fcaf3c, #ff9900);
        }
}
</style>